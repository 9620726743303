import "animate.css";
import React, { useEffect } from 'react';

function Modal({ children, classNames = "", styles = {}, onOutsite }) {

  const handleEscapeKey = (event) => {
    if (event.key === 'Escape') {
      if (onOutsite) {
        onOutsite();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  return (
    <div className="fixed w-full h-full top-0 left-0 bg-[rgba(0,0,0,0.3)] z-[999] flex items-center justify-center">
      <div className={"max-w-[390px] min-w-[390px] border-1 border-solid border-[#ccc] rounded-[10px] bg-white pb-3 " + classNames} style={styles}>
        {children}
      </div>
    </div>
  );
}

export default Modal;
