import React, { useState } from "react";
import { Button, Modal, Flex } from "antd";
import { QuestionCircleOutlined, CloseOutlined } from "@ant-design/icons";
import winBackground from "../../assets/win-background.png";
import {
  useUserStore,
  useAwardModalStore,
  useWinPrizeStore,
  useSpinConfigsStore,
} from "../../stores/store";
import CustomModal from "./CustomModal";
import CongratulationAnimation from "../Animation/CongratulationAnimation";
import { useTranslation } from "react-i18next";

const AwardModal = () => {
  const { t } = useTranslation();
  const { winPrize } = useWinPrizeStore((state) => state);
  const clearUserInfo = useUserStore((state) => state.clearUserInfo);
  const conguModalState = useAwardModalStore((state) => state.open);
  const { configs } = useSpinConfigsStore((state) => state);
  const [openAskModal, setOpenAskModal] = useState(false);
  const isShowCongra = winPrize?.prizeType == 1;
  const handleContentClick = () => {
    setOpenAskModal(true);
  };

  const handCancelAsk = () => {
    setOpenAskModal(false);
  };

  const handleContinueAsk = () => {
    clearUserInfo();
    window.location.reload();
  };

  if (conguModalState) {
    return (
      <>
        <CustomModal
          onOutsite={() => handleContentClick()}
          classNames="min-w-[95vw] lg:!min-w-[670px] !min-h-[300px] md:!min-h-[500px] bg-cover bg-center flex items-center justify-center relative"
          styles={{ backgroundImage: `url('${winBackground}')` }}
        >
          <CongratulationAnimation />
          <button
            className="absolute right-3 lg:right-6 top-3 lg:top-6 text-white z-[100]"
            onClick={() => handleContentClick()}
          >
            <CloseOutlined />
          </button>
          <div>
            <div
              className="text-[20px] lg:text-[35px] cursor-pointer">
              <p
                className="uppercase italic font-medium"
                style={{
                  color: `#${configs?.textColor}`,
                }}
              >
                {winPrize?.prizeName}
              </p>
              <div></div>
            </div>
          </div>
        </CustomModal>

        <Modal
          open={openAskModal}
          onCancel={handCancelAsk}
          footer={
            <Flex className="justify-center gap-2">
              <Button type="primary" onClick={() => handleContinueAsk()}>
                {t("button.continue_spin")}
              </Button>
              <Button onClick={() => handCancelAsk()}>{t("button.cancel")}</Button>
            </Flex>
          }
        >
          <Flex justify="center">
            <QuestionCircleOutlined className="text-5xl font-[300] text-[#87adbd]" />
          </Flex>
          <p className="text-2xl text-[#595959] font-semibold text-center my-6">
            {t("text.continue_spin")}
          </p>
        </Modal>
      </>
    );
  }
};

export default AwardModal;
