import React, { useEffect, useRef } from 'react';
import { TweenMax, Power1, Back } from 'gsap';

const CongratulationAnimation = () => {
  const particles = ['.blob', '.star'];
  const congratsSectionRef = useRef(null);
  const titleRef = useRef(null);

  useEffect(() => {
    init({
      numberOfStars: 70,
      numberOfBlobs: 45
    });

    fancyPopIn();

    const congratsSection = congratsSectionRef.current;
    congratsSection.addEventListener('click', fancyPopIn);

    return () => {
      congratsSection.removeEventListener('click', fancyPopIn);
    };
  }, []);

  const init = (properties) => {
    const congratsSection = congratsSectionRef.current;

    for (let i = 0; i < properties.numberOfStars; i++) {
      const star = document.createElement('div');
      star.className = `particle star ${i}`;
      star.innerHTML = '<span class="glyphicon glyphicon-star"></span>';
      congratsSection.appendChild(star);
    }

    for (let i = 0; i < properties.numberOfBlobs; i++) {
      const blob = document.createElement('div');
      blob.className = `particle blob ${i}`;
      congratsSection.appendChild(blob);
    }
  };

  const fancyPopIn = () => {
    reset();
    animateText();

    particles.forEach(selector => {
      animateParticles(selector);
    });
  };

  const animateText = () => {
    TweenMax.from(titleRef.current, 0.65, {
      scale: 0.1,
      opacity: 0,
      rotation: 15,
      ease: Back.easeOut.config(5),
    });
  };

  const animateParticles = (selector) => {
    const xSeed = Math.floor(Math.random() * 380) + 350;
    const ySeed = Math.floor(Math.random() * 170) + 120;

    const particles = congratsSectionRef.current.querySelectorAll(selector);

    particles.forEach(particle => {
      const speed = Math.floor(Math.random() * 5) + 1;
      const rotation = Math.floor(Math.random() * 100) + 5;
      const scale = Math.floor(Math.random() * 0.4) + 1.1;
      const x = Math.floor(Math.random() * (xSeed * 2) + 1) - xSeed;
      const y = Math.floor(Math.random() * (ySeed * 2) + 1) - ySeed;

      TweenMax.to(particle, speed, {
        x,
        y,
        ease: Power1.easeOut,
        opacity: 0,
        rotation,
        scale,
        onStart: () => {
          particle.style.display = 'block';
        },
        onComplete: () => {
          particle.style.display = 'none';
        }
      });
    });
  };

  const reset = () => {
    particles.forEach(selector => {
      const particles = congratsSectionRef.current.querySelectorAll(selector);

      particles.forEach(particle => {
        TweenMax.set(particle, { x: 0, y: 0, opacity: 1 });
      });
    });

    TweenMax.set(titleRef.current, { scale: 1, opacity: 1, rotation: 0 });
  };

  return (
    <div id="congrats" ref={congratsSectionRef}>
    </div>
  );
};

export default CongratulationAnimation;
