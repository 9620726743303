import React, { useState, useEffect } from "react";
import CryptoJS from "crypto-js";
import { message } from "antd";
import { Wheel } from "react-custom-roulette";
import { useMediaQuery } from "react-responsive";
import pointerIcon from "../../assets/pointer.png";
import buttonWheel from "../../assets/button-wheel.png";
import defaultWheel from "../../assets/default-wheel-background.png";
import {
  useUserStore,
  useAwardModalStore,
  useInforUserFormStore,
  useSpinConfigsStore,
  useWheelPrizesStore,
  useWinPrizeStore,
} from "../../stores/store";
import { SPIN_TYPES, COOKIES } from "../../utils/constants";
import {
  spinWithInfor,
  spinWithCode,
  spinWithCodeNoInfo,
  spinWithNoInfo
} from "../../services/http";
import { getCookie } from "../../utils/cookies";
import tickMp3 from "../../assets/mp3/tick.mp3";
import fanfareWinnerMp3 from "../../assets/mp3/fanfare-winner.mp3";
import CustomWheel from "./CustomWheel";
import { useTranslation } from "react-i18next";

const START_INDEX = 0;

const LuckyWheel = ({ isCustomGui }) => {
  const { t } = useTranslation();
  const audioTick = new Audio(tickMp3);
  const audioWinning = new Audio(fanfareWinnerMp3);
  const { setWinPrize } = useWinPrizeStore((state) => state);
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const userInfo = useUserStore((state) => state.userInfo);
  const setConguModalState = useAwardModalStore((state) => state.setModal);
  const setInforUserFormState = useInforUserFormStore(
    (state) => state.setModal
  );
  const { configs } = useSpinConfigsStore((state) => state);
  const { prizes, textColors, backgroundColors } = useWheelPrizesStore(
    (state) => state
  );
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const isNeededCode = configs?.luckySpinType === SPIN_TYPES.PasswordConfirm;
  const isOnlyNeededCode =
    configs?.luckySpinType === SPIN_TYPES.PasswordConfirm_NoInfo;
  const isNoInfo = configs?.luckySpinType === SPIN_TYPES.NoInfo;

  const handleSpinClick = async () => {
    let response;

    if (spinning) return;

    const generateKey = getCookie(COOKIES.GENERATE_KEY);
    const body = {
      ...userInfo,
      key: CryptoJS.MD5(generateKey).toString(),
      refId: configs?.id,
    };

    if (!isNoInfo && !userInfo) {
      setInforUserFormState(true);
      return;
    }

    setSpinning(true);

    if (isNeededCode) {
      response = await handleSpinWithCode(body);
    } else if (isOnlyNeededCode) {
      response = await handleSpinWithCodeNoInfo(body);
    } else if (isNoInfo) {
      response = await handleSpinWithNoInfo(body);
    } else {
      response = await handleSpinWithInfor(body);
    }

    if (response?.data) {
      const newIndex = prizes.findIndex((el) => el.id === response?.data);
      startAudioTick();
      setPrizeNumber(newIndex);
      setWinPrize(prizes[newIndex]);
      setMustSpin(true);
    } else {
      message.error(t("message.no_prize"));
    }
  };

  const startAudioTick = async () => {
    for (let index = 0; index < 38; index++) {
      audioTick.pause();
      audioTick.currentTime = 0;

      if (index <= 3) {
        audioTick.play();
        await sleep(300);
      } else if (index > 3 && index < 29) {
        audioTick.play();
        await sleep(70);
      } else if (index > 29 && index < 33) {
        audioTick.play();
        await sleep(500);
      } else {
        audioTick.play();
        await sleep(600);
      }
    }
  };

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const playSoundWinning = () => {
    audioWinning.pause();
    audioWinning.currentTime = 0;

    audioWinning.play();
  };

  const handleSpinWithInfor = async (body) => {
    try {
      const response = await spinWithInfor(body);
      if (response.code !== 200) {
        message.error(response.message);
        setSpinning(false);
        return;
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSpinWithCode = async (body) => {
    try {
      const response = await spinWithCode(body);
      if (response.code !== 200) {
        message.error(response.message);
        return;
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSpinWithCodeNoInfo = async (body) => {
    try {
      const response = await spinWithCodeNoInfo(body);
      if (response.code !== 200) {
        message.error(response.message);
        return;
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSpinWithNoInfo = async (body) => {
    try {
      const response = await spinWithNoInfo(body);
      if (response.code !== 200) {
        message.error(response.message);
        return;
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleStopSpinning = () => {
    setMustSpin(false);
    setSpinning(false);

    setTimeout(() => {
      setConguModalState(true);
      playSoundWinning();
    }, 1000);
  };

  return (
    <>
      {prizes?.length > 1 && (
        <div
          className="container flex justify-center items-center relative w-[85vw] h-[85vw] lg:w-[85vh] lg:h-[85vh]"
        >
          <div
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full z-10"
            style={{
              display: isCustomGui ? "block" : "none",
            }}
          >
            <img
              className="w-full h-full"
              src={defaultWheel}
              alt="background-wheel"
            />
          </div>
          <div
            className={
              "flex items-center justify-center w-full h-full relative " +
              (isCustomGui
                ? "rouletteContainerDefault"
                : "rouletteContainerCustom")
            }
          >
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber || 0}
              data={prizes || []}
              radiusLineColor="transparent"
              outerBorderColor="transparent"
              textColors={textColors}
              fontWeight={600}
              textDistance={58}
              fontSize={[13]}
              spinDuration={[0.8]}
              pointerProps={{
                style: {
                  display: "none",
                },
              }}
              backgroundColors={backgroundColors}
              onStopSpinning={() => handleStopSpinning()}
              startingOptionIndex={START_INDEX}
            />
            
            <CustomWheel
              isCustomGui={isCustomGui}
              configs={configs}
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={prizes}
              backgroundColors={backgroundColors}
              textColors={textColors}
              startingOptionIndex={START_INDEX}
            />
          </div>
          {!isCustomGui && (
            <div className="absolute left-1/2 -translate-x-1/2 z-50 -top-[34px] lg:-top-[64px]">
              <img
                src={pointerIcon}
                alt="images"
                className="h-auto w-[80px] lg:w-[160px]"
              />
            </div>
          )}

          <button className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
            <div
              className={
                "rouletteMain_box_btn w-[80px] h-[80px] md:w-[160px] md:h-[160px] lg:w-[180px] lg:h-[180px] cursor-pointer flex items-center justify-center text-[#fff5ba] font-bold text-sm md:text-lg uppercase " +
                (isCustomGui
                  ? "2xl:w-[15vh] 2xl:h-[15vh]"
                  : "2xl:w-[19vh] 2xl:h-[19vh]")
              }
              style={{
                backgroundImage: `url('${buttonWheel}')`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
              alt="action"
              onClick={() => handleSpinClick()}
            >
              {t("button.spin")}
            </div>
          </button>
        </div>
      )}
    </>
  );
};

export default LuckyWheel;
