import React from "react";

import { CaretDownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

const SelectGroup = ({
  items,
  title,
  disabled,
  onChange,
  selectedItem,
  textColor,
  buttonColor,
}) => {
  const onClick = ({ key }) => {
    const found = items.find((item) => item.key == key);

    if (onChange) {
      if (found?.key) {
        onChange(found);
      }
    }
  };

  return (
    <Dropdown menu={{ items, onClick }} disabled={disabled} trigger="click">
      <button
        className="text-sm md:text-base w-auto min-w-[140px] md:min-w-[170px] px-1 md:px-4 h-[40px] md:h-[50px] font-medium md:font-bold rounded-[8px] border-[2px] border-red flex items-center justify-center gap-2 shadow-lg"
        style={{
          cursor: disabled ? "not-allowed" : "pointer",
          backgroundColor: `#${buttonColor}`,
          color: `#${textColor}`,
        }}
      >
        {selectedItem?.key ? (
          selectedItem?.label
        ) : (
          <>
            <span>{title}</span>
            <CaretDownOutlined />
          </>
        )}
      </button>
    </Dropdown>
  );
};

export default SelectGroup;
