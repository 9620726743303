import ServerError from "./pages/ServerError";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import 'animate.css';
import "./i18n";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Home />}></Route>
        <Route path="/server-errors" element={<ServerError />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
