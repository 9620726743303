import React, { useState } from "react";
import { Button, Modal, Flex } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const ConfirmModal = ({ openModal, title, content, onClose, onOk, loading, textOk }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={openModal}
      onCancel={onClose}
      footer={
        <Flex className="justify-center gap-2">
          <Button type="primary" loading={loading} onClick={() => onOk()}>
            {textOk}
          </Button>
          <Button onClick={() => onClose()}>{t("button.cancel")}</Button>
        </Flex>
      }
    >
      <Flex justify="center">
        <QuestionCircleOutlined className="text-5xl text-[#f8bb86]" />
      </Flex>
      <p className="text-lg font-semibold text-center mt-6 uppercase">
        {title}
      </p>
      <p className="text-base text-center mb-4 mt-2">
        {content}
      </p>
    </Modal>
  );
};

export default ConfirmModal;
