import React from "react";
import { useUserStore } from "../stores/store";
import { useTranslation } from "react-i18next";

const InformationSpin = ({ configs }) => {
  const { t } = useTranslation();
  const userInfo = useUserStore((state) => state.userInfo);

  if (!userInfo) {
    return;
  }

  return (
    <div className="w-[90vw] lg:w-[600px] mt-[5vh] lg:mt-[4vh] lg:ml-[4vh] mx-auto animate__animated animate__backInDown">
      <div className="bg-white rounded-md text-xs md:text-base">
        <p
          className="px-2 py-2 md:px-3 md:py-3 rounded-tl-md rounded-tr-md"
          style={{
            backgroundColor: `#${configs?.textColor}`,
          }}
        >
          <span className={"p-1 uppercase font-bold m-0 " + (configs?.textColor ? "text-white" : "text-[#000]") }>
            {t("text.spin_number")}
          </span>
        </p>
        {userInfo?.code && (
          <p className="m-0 p-0 table py-2 w-full pl-3 border-t border-t-solid border-t-[#e6e6e6]">
            <span className="bg-white p-0">{t("info_user_form.label.code")}:</span>
            <span className="p-1 bg-white font-semibold">{userInfo?.code}</span>
          </p>
        )}
        <p className="m-0 p-0 table py-2 w-full pl-3 border-t border-t-solid border-t-[#e6e6e6]">
          <span className="bg-white p-0">{t("info_user_form.label.phone")}:</span>
          <span className="p-1 bg-white font-semibold">{userInfo?.playerPhone}</span>
        </p>
        <p className="m-0 p-0 table py-2 w-full pl-3 border-t border-t-solid border-t-[#e6e6e6]">
          <span className="bg-white p-0">{t("info_user_form.label.name")}:</span>
          <span className="p-1 bg-white font-semibold">{userInfo?.playerName}</span>
        </p>
      </div>
    </div>
  );
};

export default InformationSpin;
