import { Button } from "antd";
import notFoundIcon from "../assets/event-404.svg";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div className="flex h-[calc(100vh-80px)] items-center justify-center p-5 w-full bg-white">
      <div className="flex items-center flex-col gap-6">
        <div>
          <img src={notFoundIcon} className="w-[calc(100vh-200px)]" />
        </div>
        <p className="text-slate-600 lg:text-lg">
          {t("text.not_found")}
        </p>
        <Button
          type="primary"
          danger
          className="p-6 text-xl"
          onClick={reloadPage}
        >
          {t("button.refresh")}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
