export const COOKIES = {
  PASSWORD: (eventName)=>{
    return `SPIN_PASSWORD_${eventName}`
  },
  GENERATE_KEY: "SPIN_GENERATE_KEY",
};

export const EVENT_TYPES = {
  WHEEL: 0,
  DIAL: 1
};

export const SPIN_GUI_TYPES = {
  DEFAULT: 0,
  CUSTOM: 1
}

export const DIAL_TYPES = {
  NUMBER_WORD: 0,
  NUMBER: 1,
  WORD: 2
}

export const SPIN_TYPES = {
  NoPasswordConfirm_NoPhoneDuplicate: 0,
  NoPasswordConfirm_PhoneDuplicate: 1,
  PasswordConfirm: 2,
  PasswordConfirm_NoInfo: 3,
  NoInfo: 4,
}

export const LANGUAGES = ["vi", "th"];