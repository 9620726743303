import React, { useState } from "react";
import CustomModal from "../Modal/CustomModal";
import { Button, Form, Input, Flex, message } from "antd";
import { verifySpinPassword } from "../../services/http";
import { setCookie } from "../../utils/cookies";
import { COOKIES } from "../../utils/constants";
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next";

const InputPassForm = ({ reload, spinId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;
  const lastSegment = pathname.substring(pathname.lastIndexOf("/") + 1);
  const onFinish = async (values) => {
    setLoading(true);

    try {
      const body = {
        ...values,
        spinKey: spinId
      }
      const response = await verifySpinPassword(body);

      if (response.code !== 200) {
        message.error(response.message);
        return;
      }

      setCookie(COOKIES.PASSWORD(lastSegment), values?.password, 1);
      reload();

    } catch (error) {
      console.error("Error in onFinish:", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-[100vw] h-[100vh] bg-[rgba(0,0,0,0.4)]">
      <CustomModal classNames="animate__animated animate__bounceIn md:!min-w-[490px]">
        <span className="block bg-[rgba(6,21,40,0.05)] text-[#000] px-[15px] py-[12px] font-semibold text-[18px] leading-6 text-center">
          {t("pass_form.title")}
        </span>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="px-4 py-4"
        >
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("pass_form.required"),
              },
            ]}
          >
            <Input size="large" placeholder={t("pass_form.placeholder")} />
          </Form.Item>
          <Flex justify="center" className="gap-2">
            <Button
              loading={loading}
              type="primary"
              className="bg-[#BF1E2E] hover:!bg-[#802b33] w-full"
              size="large"
              htmlType="submit"
            >
              {t("button.confirm")}
            </Button>
          </Flex>
        </Form>
      </CustomModal>
    </div>
  );
};

export default InputPassForm;
