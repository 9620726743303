import React from "react";

const CellBox = ({
  textColor,
  buttonColor,
  backgroundNumberColor,
  customT,
  isShowCell,
  cellTotal,
}) => {
  return (
    <>
      {isShowCell && (
        <div
          className="w-fit mx-auto h-[78px] md:h-[139px] overflow-hidden p-[5px] rounded-[10px] mt-[30px] flex gap-2 "
          style={{
            border: `4px solid #${buttonColor}`,
          }}
        >
          {[...Array(cellTotal).keys()].map((i) => (
            <div key={i}>
              <div
                id={`machine${i + 1}`}
                className="rounded-[10px] w-[50px] h-[60px] md:w-[105px] md:h-[120px]"
                style={{
                  backgroundColor: `#${backgroundNumberColor}`,
                  color: `#${textColor}`,
                }}
              >
                {customT[i + 1] &&
                  customT[i + 1].split(",").map((val, idx) => (
                    <div
                      key={idx}
                      className="h-[60px] md:h-[120px] text-center font-bold leading-[1.09] text-[52px] md:text-[105px]"
                      style={{
                        textShadow:
                          "0px 0px #8e8e8e, 0px 0px #2d2d2d, 1px 0px #424242",
                      }}
                    >
                      {val?.toUpperCase()}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {!isShowCell && (
        <div
          className="w-fit mx-auto h-[139px] overflow-hidden p-[5px] rounded-[10px] mt-[30px] flex gap-2 "
          style={{
            border: `4px solid #${buttonColor}`,
          }}
        >
          {[...Array(cellTotal).keys()].map((i) => (
            <div key={i}>
              <div
                className="rounded-[10px] w-[105px] h-[120px]"
                style={{
                  backgroundColor: `#${backgroundNumberColor}`,
                  color: `#${textColor}`,
                }}
              >
                {customT[i + 1] &&
                  customT[i + 1].split(",").map((val, idx) => (
                    <div
                      key={idx}
                      className="h-[120px] text-center font-bold leading-[1.09]"
                      style={{
                        fontSize: "105px",
                        textShadow:
                          "0px 0px #8e8e8e, 0px 0px #2d2d2d, 1px 0px #424242",
                      }}
                    >
                      {val?.toUpperCase()}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CellBox;
