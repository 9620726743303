import axios from "axios";

const url = process.env.REACT_APP_API_URL;

const baseConfig = {
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
};

export const instanceBase = axios.create({
  baseURL: url,
  ...baseConfig,
});
instanceBase.interceptors.request.use(
    function (config) {
      let lang = localStorage.getItem("i18nextLng") ?? "vi";

      const supportLangs = ["vi", "th"];
      if (!supportLangs.includes(lang)) {
          lang = "vi";
      }

      config.headers["Accept-Language"] = lang;
        return config;
    }
);


instanceBase.interceptors.response.use(
  function (response) {
    if (response.data && response.data.code >= 500) {
      // window.location.href = "/server-errors";
    } else if (response.data.code == 404) {
      // window.location.href = "/404";
    }
    return response.data ? response.data : response;
  }
);

class APIBase {
  get = async (url, config) => {
    return instanceBase.get(url, { ...config });
  };

  post = (url, data, config) => {
    return instanceBase.post(url, data, { ...config });
  };

  put = (url, data, config) => {
    return instanceBase.put(url, data, { ...config });
  };

  patch = (url, data, config) => {
    return instanceBase.patch(url, data, { ...config });
  };

  delete = (url, config) => {
    return instanceBase.delete(url, { ...config });
  };
}

export { APIBase };
