import React, { useEffect, useRef, useState } from "react";
import WebFont from "webfontloader";
import { RotationContainer, RouletteContainer } from "./styles";
import DrawWheel from "./DrawWheel";

const WEB_FONTS = [
  "arial",
  "verdana",
  "tahoma",
  "trebuchet ms",
  "times",
  "garamond",
  "brush script mt",
  "courier new",
  "georgia",
  "helvetica",
  "times new roman",
  "serif",
  "sans-serif",
  "monospace",
  "cursive",
  "fantasy",
];

const STARTED_SPINNING = "started-spinning-custom";
const START_SPINNING_TIME = 2600;
const CONTINUE_SPINNING_TIME = 750;
const STOP_SPINNING_TIME = 8000;

const DEFAULT_BACKGROUND_COLORS = ["darkgrey", "lightgrey"];
const DEFAULT_TEXT_COLORS = ["black"];
const DEFAULT_FONT_FAMILY = "Nunito";
const DEFAULT_FONT_SIZE = 20;
const DEFAULT_FONT_WEIGHT = "bold";
const DEFAULT_FONT_STYLE = "normal";
const DISABLE_INITIAL_ANIMATION = false;

const isCustomFont = (font) =>
  !!font && !WEB_FONTS.includes(font.toLowerCase());

const getRotationDegrees = (prizeNumber, numberOfPrizes, randomDif = false) => {
  const degreesPerPrize = 360 / numberOfPrizes;

  const initialRotation = 43 + degreesPerPrize / 2;

  const randomDifference = (-1 + Math.random() * 2) * degreesPerPrize * 0.35;

  const perfectRotation =
    degreesPerPrize * (numberOfPrizes - prizeNumber) - initialRotation;
  
  const imperfectRotation =
    degreesPerPrize * (numberOfPrizes - prizeNumber) -
    initialRotation +
    randomDifference;

  const prizeRotation = randomDif ? imperfectRotation : perfectRotation;

  return numberOfPrizes - prizeNumber > numberOfPrizes / 2
    ? -360 + prizeRotation
    : prizeRotation;
};

const getQuantity = (prizeMap) => prizeMap.slice(-1)[0].slice(-1)[0] + 1;
const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

const makeClassKey = (length) => {
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

const CustomWheel = ({
  isCustomGui,
  configs,
  mustStartSpinning,
  prizeNumber,
  data,
  startingOptionIndex = -1,
  textColors,
  backgroundColors,
}) => {
  const version = Date.now();
  const [prizeMap, setPrizeMap] = useState([[0]]);
  const [startRotationDegrees, setStartRotationDegrees] = useState(0);
  const [hasStartedSpinning, setHasStartedSpinning] = useState(false);
  const [hasStoppedSpinning, setHasStoppedSpinning] = useState(false);
  const [isCurrentlySpinning, setIsCurrentlySpinning] = useState(false);
  const [finalRotationDegrees, setFinalRotationDegrees] = useState(0);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const mustStopSpinning = useRef(false);
  const [rouletteUpdater, setRouletteUpdater] = useState(false);
  const [loadedImagesCounter, setLoadedImagesCounter] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const [isFontLoaded, setIsFontLoaded] = useState(false);
  const [revertRotateImage, setRevertRotateImage] = useState(0);

  const spinDuration = 0.8;
  const normalizedSpinDuration = Math.max(0.01, spinDuration);
  const startSpinningTime = START_SPINNING_TIME * normalizedSpinDuration;
  const continueSpinningTime = CONTINUE_SPINNING_TIME * normalizedSpinDuration;
  const stopSpinningTime = STOP_SPINNING_TIME * normalizedSpinDuration;
  const disableInitialAnimation = DISABLE_INITIAL_ANIMATION;
  const fontFamily = WEB_FONTS[0];
  const fontSize = DEFAULT_FONT_SIZE;
  const fontWeight = DEFAULT_FONT_WEIGHT;
  const fontStyle = DEFAULT_FONT_STYLE;

  const classKey = makeClassKey(5);

  const totalSpinningTime =
    startSpinningTime + continueSpinningTime + stopSpinningTime;

  useEffect(() => {
    let initialMapNum = 0;
    const auxPrizeMap = [];
    const dataLength = data?.length || 0;
    const wheelDataAux = [{ option: "", optionSize: 1 }];
    const fontsToFetch = isCustomFont(fontFamily?.trim()) ? [fontFamily] : [];

    for (let i = 0; i < dataLength; i++) {
      let fontArray = data[i]?.style?.fontFamily?.split(",") || [];
      fontArray = fontArray.map((font) => font.trim()).filter(isCustomFont);
      fontsToFetch.push(...fontArray);

      wheelDataAux[i] = {
        ...data[i],
        style: {
          backgroundColor:
            data[i].style?.backgroundColor ||
            backgroundColors?.[i % backgroundColors?.length] ||
            DEFAULT_BACKGROUND_COLORS[0],
          fontFamily:
            data[i].style?.fontFamily || fontFamily || DEFAULT_FONT_FAMILY,
          fontSize: data[i].style?.fontSize || fontSize || DEFAULT_FONT_SIZE,
          fontWeight:
            data[i].style?.fontWeight || fontWeight || DEFAULT_FONT_WEIGHT,
          fontStyle:
            data[i].style?.fontStyle || fontStyle || DEFAULT_FONT_STYLE,
          textColor:
            data[i].style?.textColor ||
            textColors?.[i % textColors?.length] ||
            DEFAULT_TEXT_COLORS[0],
        },
      };
      auxPrizeMap.push([]);
      for (let j = 0; j < (wheelDataAux[i].optionSize || 1); j++) {
        auxPrizeMap[i][j] = initialMapNum++;
      }
      // if (data[i].image) {
      //   setTotalImages((prevCounter) => prevCounter + 1);

      //   const img = new Image();
      //   img.src = data[i].image?.uri || "";
      //   img.onload = () => {
      //     img.height = 200 * (data[i].image?.sizeMultiplier || 1);
      //     img.width = (img.naturalWidth / img.naturalHeight) * img.height;
      //     wheelDataAux[i].image = {
      //       uri: data[i].image?.uri || "",
      //       offsetX: data[i].image?.offsetX || 0,
      //       offsetY: data[i].image?.offsetY || 0,
      //       landscape: data[i].image?.landscape || false,
      //       sizeMultiplier: data[i].image?.sizeMultiplier || 1,
      //       _imageHTML: img,
      //     };
      //     setLoadedImagesCounter((prevCounter) => prevCounter + 1);
      //     setRouletteUpdater((prevState) => !prevState);
      //   };
      // }
    }

    if (fontsToFetch?.length > 0) {
      try {
        WebFont.load({
          google: {
            families: Array.from(
              new Set(fontsToFetch.filter((font) => !!font))
            ),
          },
          timeout: 1000,
          fontactive() {
            setRouletteUpdater(!rouletteUpdater);
          },
          active() {
            setIsFontLoaded(true);
            setRouletteUpdater(!rouletteUpdater);
          },
        });
      } catch (err) {
        console.log("Error loading webfonts:", err);
      }
    } else {
      setIsFontLoaded(true);
    }

    setPrizeMap(auxPrizeMap);
    setStartingOption(startingOptionIndex, auxPrizeMap);
    setIsDataUpdated(true);
  }, [data, backgroundColors, textColors]);

  const getRouletteClass = () => {
    if (hasStartedSpinning) {
      return STARTED_SPINNING;
    }
    return "";
  };

  const startSpinning = () => {
    setHasStartedSpinning(true);
    setHasStoppedSpinning(false);
    mustStopSpinning.current = true;
    setTimeout(() => {
      if (mustStopSpinning.current) {
        mustStopSpinning.current = false;
        setHasStartedSpinning(false);
        setHasStoppedSpinning(true);
        // onStopSpinning();
      }
    }, totalSpinningTime);
  };

  useEffect(() => {
    if (mustStartSpinning && !isCurrentlySpinning) {
      setIsCurrentlySpinning(true);
      startSpinning();
      const selectedPrize =
        prizeMap[prizeNumber][
          Math.floor(Math.random() * prizeMap[prizeNumber]?.length)
        ];
      const finalRotationDegreesCalculated = getRotationDegrees(
        selectedPrize,
        getQuantity(prizeMap)
      );
      setFinalRotationDegrees(finalRotationDegreesCalculated);
    }
  }, [mustStartSpinning]);

  useEffect(() => {
    if (hasStoppedSpinning) {
      setIsCurrentlySpinning(false);
      setStartRotationDegrees(finalRotationDegrees);
    }
  }, [hasStoppedSpinning]);

  const setStartingOption = (optionIndex, optionMap) => {
    if (startingOptionIndex >= 0) {
      const idx = Math.floor(optionIndex) % optionMap?.length;
      const startingOption =
        optionMap[idx][Math.floor(optionMap[idx]?.length / 2)];

      const startRotation = getRotationDegrees(
        startingOption,
        getQuantity(optionMap),
        true
      );

      setRevertRotateImage(startRotation);
      setStartRotationDegrees(startRotation);
    }
  };

  if (!isDataUpdated) {
    return null;
  }

  return (
    <RouletteContainer
      style={
        !isFontLoaded ||
        (totalImages > 0 && loadedImagesCounter !== totalImages)
          ? { visibility: "hidden" }
          : {}
      }
    >
      <RotationContainer
        className={getRouletteClass()}
        classKey={`custom-${classKey}`}
        startSpinningTime={startSpinningTime}
        continueSpinningTime={continueSpinningTime}
        stopSpinningTime={stopSpinningTime}
        startRotationDegrees={startRotationDegrees}
        finalRotationDegrees={finalRotationDegrees}
        disableInitialAnimation={disableInitialAnimation}
      >
        {isCustomGui ? (
          <div
            style={{
              transform: `rotate(${-revertRotateImage + 46.5}deg)`,
            }}
          >
            <DrawWheel data={data} />
          </div>
        ) : (
          <img
            src={configs?.spinImageUrl}
            alt="image-wheel"
            className="w-full h-full object-contain"
            style={{
              transform: `rotate(${-revertRotateImage + 46.5}deg)`,
            }}
          />
        )}
      </RotationContainer>
    </RouletteContainer>
  );
};

export default CustomWheel;
