import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { Button, Form, Input, Flex, message } from "antd";
import CustomModal from "../Modal/CustomModal";
import {
  useUserStore,
  useInforUserFormStore,
  useSpinConfigsStore,
} from "../../stores/store";
import { SPIN_TYPES, COOKIES } from "../../utils/constants";
import {
  validateInfor,
  validateSpinCode,
  validateSpinCodeNoInfo,
  validateNoInfo,
} from "../../services/http";
import { getCookie } from "../../utils/cookies";
import { useTranslation } from "react-i18next";
import foundMp3 from "../../assets/mp3/foundMa.mp3";

const InformationUserForm = ({ open, onClose }) => {
  const { t } = useTranslation();
  const audioFound = new Audio(foundMp3);
  const setUserInfo = useUserStore((state) => state.setUserInfo);
  const modalState = useInforUserFormStore((state) => state.open);
  const setModalState = useInforUserFormStore((state) => state.setModal);
  const { configs } = useSpinConfigsStore((state) => state);
  const isNeededCode = configs?.luckySpinType === SPIN_TYPES.PasswordConfirm;
  const isOnlyNeededCode =
    configs?.luckySpinType === SPIN_TYPES.PasswordConfirm_NoInfo;
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const onFinish = async (values) => {
    if (!configs) {
      message.error("Không tìm thấy vòng quay");
      return;
    }

    setIsLoading(true);
    const generateKey = getCookie(COOKIES.GENERATE_KEY);

    const body = {
      ...values,
      refId: configs.id,
      key: CryptoJS.MD5(generateKey).toString(),
    };
    let isPassValidation;

    if (isNeededCode) {
      isPassValidation = await handleValidateSpinCode(body);
    } else if (isOnlyNeededCode) {
      isPassValidation = await handleValidateSpinCodeNoInfo(body);
    } else {
      isPassValidation = await handleValidateInfor(body);
    }

    setIsLoading(false);

    if (isPassValidation) {
      setUserInfo(values);
      setModalState(false);
      setTimeout(() => {
        playSoundFound();
      }, 500);
    }
  };

  const handleValidateInfor = async (body) => {
    try {
      const response = await validateInfor(body);

      if (response.code !== 200) {
        message.error(response.message);
        return false;
      }

      return true;
    } catch (error) {
      message.error(error);
      return false;
    }
  };

  const handleValidateSpinCode = async (body) => {
    try {
      const response = await validateSpinCode(body);

      if (response.code !== 200) {
        message.error(response.message);
        return false;
      }

      return true;
    } catch (error) {
      message.error(error);
      return false;
    }
  };

  const handleValidateSpinCodeNoInfo = async (body) => {
    try {
      const response = await validateSpinCodeNoInfo(body);

      if (response.code !== 200) {
        message.error(response.message);
        return false;
      }

      return true;
    } catch (error) {
      message.error(error);
      return false;
    }
  };

  const handleValidateNoInfo = async (body) => {
    try {
      const response = await validateNoInfo(body);

      if (response.code !== 200) {
        message.error(response.message);
        return false;
      }

      return true;
    } catch (error) {
      message.error(error);
      return false;
    }
  };

  const handleCancel = () => {
    setModalState(false);
  };

  const playSoundFound = () => {
    audioFound.pause();
    audioFound.currentTime = 0;

    audioFound.play();
  };

  if (modalState) {
    return (
      <CustomModal classNames="min-w-[95vw] lg:min-w-[390px] animate__animated animate__bounceIn">
        <span className="block bg-[rgba(6,21,40,0.05)] text-[#000] px-[15px] py-[12px] font-semibold text-[16px]">
          {t("info_user_form.title")}
        </span>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="px-4 py-4"
        >
          {(isNeededCode || isOnlyNeededCode) && (
            <Form.Item
              name="code"
              label={t("info_user_form.label.code")}
              rules={[
                { required: true, message: t("info_user_form.required.code") },
              ]}
            >
              <Input
                size="large"
                placeholder={t("info_user_form.placeholder.code")}
              />
            </Form.Item>
          )}
          {!isOnlyNeededCode && (
            <>
              <Form.Item
                name="playerPhone"
                label={t("info_user_form.label.phone")}
                rules={[
                  {
                    required: true,
                    message: t("info_user_form.required.phone"),
                  },
                  {
                    pattern: /^[0-9]{10,15}$/,
                    message: t("info_user_form.validated.phone"),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t("info_user_form.placeholder.phone")}
                />
              </Form.Item>
              <Form.Item
                name="playerName"
                label={t("info_user_form.label.name")}
                rules={[
                  {
                    required: true,
                    message: t("info_user_form.required.name"),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t("info_user_form.placeholder.name")}
                />
              </Form.Item>
            </>
          )}

          <Flex justify="end" className="gap-2">
            <Button
              loading={isLoading}
              type="primary"
              className="bg-[#1e9c56] hover:!bg-[#1e9c56]"
              size="large"
              htmlType="submit"
            >
              {t("button.confirm")}
            </Button>
            <Button
              size="large"
              className="bg-[#CD0202] text-white hover:!bg-[#CD0202] hover:!text-white hover:!border-current"
              onClick={handleCancel}
            >
              {t("button.close")}
            </Button>
          </Flex>
        </Form>
      </CustomModal>
    );
  }
};

export default InformationUserForm;
