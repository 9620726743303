import React, { useEffect, useState } from "react";
import resultTitle from "../assets/result-title.png";
import { getPlayers } from "../services/http";
import { useConnectionSignalRStore } from "../stores/store";
import { useTranslation } from "react-i18next";
import { sleep } from "../utils/utils";

const ListPlayers = ({ configs }) => {
  const { t } = useTranslation();
  const { connectionSignalR } = useConnectionSignalRStore((state) => state);
  const { textColor } = configs;
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    fetchPlayers();
  }, []);

  const realHandler = async (data) => {
    await sleep(9000);
    setPlayers((prev) => [data, ...prev]);
  };

  const fakeHandler = (data) => {
    const prizes = configs?.prizes || [];
    const randomIndex = Math.floor(Math.random() * prizes?.length);
    const prizeName = prizes[randomIndex]?.prizeName;

    const playerObject = {
      ...data,
      prizeName,
    };

    setPlayers((prev) => [playerObject, ...prev]);
  };

  const connectInvoke = async () => {
    try {
      if (connectionSignalR?.connection?.state === "Connected") {
        await connectionSignalR?.connection?.invoke(
          "sendMessageToRefId",
          configs?.id,
          0
        );

        await connectionSignalR?.connection?.on("SpinPlayer", realHandler);
        await connectionSignalR?.connection?.on("SpinFakePlayer", fakeHandler);
      }
    } catch (error) {
      console.log("-------->error: ", error);
    }
  };

  useEffect(() => {
    connectInvoke();

    return () => {
      if (connectionSignalR) {
        connectionSignalR?.connection?.stop().then(() => console.log("Disconnected"));
      }
    };
  }, [connectionSignalR]);

  const fetchPlayers = async () => {
    try {
      const { data } = await getPlayers(configs?.id, { pageSize: 99 });
      setPlayers(data || []);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="h-full items-center flex w-full justify-center lg:w-[25vw] mb-5 lg:mb-0 flex-col">
      <div
        className="container w-[80%] 2xl:w-[460px] h-[45px] 2xl:h-[55px] bg-no-repeat bg-center bg-[length:100%_100%] my-1"
        style={{
          backgroundImage: `url(${resultTitle})`,
        }}
      >
        <div className="font-medium text-base 2xl:text-[25px] text-white flex items-center justify-center h-full uppercase">
          {t("text.list_award")}
        </div>
      </div>
      <div className="container flex relative w-full items-center justify-center mt-2">
        <div
          className="h-[340px] 2xl:h-[430px] w-full flex flex-col rounded-lg px-4"
          style={{
            background: `#${textColor}`,
          }}
        >
          <div className="w-full flex h-[50px] items-center text-white text-lg">
            <div className="w-[40%]">{t("text.phone")}</div>
            <div className="flex w-[60%] flex-1 justify-center">
              {t("text.award")}
            </div>
          </div>
          <div className="mt-7 flex flex-col gap-4 w-full 0 h-full text-white text-base overflow-y-auto overflow-x-hidden scroll-smooth custom-scroll custom-scroll-none">
            {players.map(
              ({ id, playerName, prizeName, playerPhone }, index) => {
                return (
                  <div
                    key={players?.length - index}
                    className={"flex w-full gap-2 winner-item"}
                  >
                    <div className="w-[40%] flex">
                      {playerPhone ? playerPhone.slice(0, -3) + "***" : ""}
                    </div>
                    <div className="w-[60%] flex flex-1 justify-center">
                      {prizeName}
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListPlayers;
